import React, { useEffect, useRef } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";

function ModalDetails({ course, onClose }) {
  const descriptionRef = useRef(null);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const handleDownloadPDF = () => {
    const doc = new jsPDF("p", "mm", "a4");
    let yPosition = 10;

    // Titre principal
    doc.setFontSize(18);
    doc.setFont("helvetica", "bold");
    doc.text(course.titre, 10, yPosition);
    yPosition += 10;

    // Informations sur l'auteur et la date de publication
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text(`Auteur : ${course.auteur}`, 10, yPosition);
    yPosition += 7;
    doc.text(`Date de publication : ${course.date_publication}`, 10, yPosition);
    yPosition += 15;

    // Section Description avec mise en forme basique
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text("Description", 10, yPosition);
    yPosition += 10;

    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");

    // Découpage du contenu HTML en texte brut
    const descriptionText = descriptionRef.current.innerText.split("\n");
    descriptionText.forEach((line) => {
      const splitText = doc.splitTextToSize(line, 180); // Limite la largeur du texte
      doc.text(splitText, 10, yPosition);
      yPosition += splitText.length * 6; // Ajuste la position en fonction de la longueur
      if (yPosition > 280) {
        // Si le contenu dépasse la page
        doc.addPage();
        yPosition = 10;
      }
    });

    doc.save(`${course.titre}.pdf`);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50 px-4">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg relative overflow-hidden">
        <button
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 text-2xl"
          onClick={onClose}
        >
          &times;
        </button>
        <h2 className="text-2xl font-semibold mb-2 text-gray-800">
          {course.titre}
        </h2>
        <div className="text-gray-600 mb-4">
          <p className="mb-1">
            <strong>Auteur :</strong> {course.auteur}
          </p>
          <p className="text-sm text-gray-500">
            <strong>Date de publication :</strong> {course.date_publication}
          </p>
        </div>

        {/* Contenu scrollable */}
        <div
          ref={descriptionRef}
          className="border-t border-gray-200 pt-4 mt-4 max-h-[60vh] overflow-y-auto"
        >
          {/* <h3 className="text-lg font-medium mb-3 text-gray-700">
            Description
          </h3> */}
          <div
            className="text-gray-700 leading-relaxed text-sm"
            dangerouslySetInnerHTML={{ __html: course.description }}
          />
        </div>

        {/* Bouton de téléchargement */}
        <div className="mt-4 flex justify-end">
          <button
            onClick={handleDownloadPDF}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700 transition"
          >
            Télécharger en PDF
          </button>
        </div>
      </div>
    </div>
  );
}

export default ModalDetails;
