import React from "react";
import Title from "../Components/Title";
import AboutHome from "../Components/AboutHome";
import Word from "../Components/Word";
import Pub from "../Components/Pub";

function Home() {
  return (
    <div>
      <Title />
      <AboutHome />
      <Word />
      <Pub />
    </div>
  );
}

export default Home;
