import React, { useEffect, useState } from "react";
import { getFiangonana } from "../../services/bdd";

function Sampandraharaha() {
  const [fiangonana, setFiangonana] = useState([]);

  useEffect(() => {
    const FiangonanaData = async () => {
      try {
        const fiangonanadata = await getFiangonana();
        setFiangonana(fiangonanadata);
        console.log(fiangonanadata);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des données fiangonana:",
          error
        );
      }
    };

    FiangonanaData();
  }, []);

  return (
    <div className="max-w-6xl mx-auto w-full">
      <h1 className="text-2xl md:text-3xl font-bold my-10 text-center">
        Sampandraharaha
      </h1>
      <hr />
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12 p-4">
        {fiangonana.map((toby, index) => (
          <a
            key={index}
            href={toby.url}
            target="_blank"
            rel="noopener noreferrer"
            className="relative w-full max-w-sm group cursor-pointer"
            data-aos="zoom-in"
          >
            <img
              src={toby.imageUrl}
              alt={toby.title}
              className="w-full h-64 md:h-80 lg:h-80 object-contain rounded-xl"
            />
            <div className="absolute inset-0 flex flex-col justify-end items-center bg-gradient-to-t from-blue-900 to-transparent text-white p-4">
              <h2 className="text-xl md:text-2xl lg:text-4xl font-bold mb-2 text-center">
                {toby.title}
              </h2>
              <p className="text-sm md:text-md lg:text-lg text-white text-center">
                {toby.description}
              </p>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}

export default Sampandraharaha;
