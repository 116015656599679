import React from "react";
import backgroundImage from "../../assets/images/BG/FLMTitle.png";

function TitleFLM({ texttitle, heightTitle }) {
  return (
    <div
      className="w-full overflow-hidden"
      style={{ height: heightTitle || "auto" }}
      data-aos="fade-down"
      data-aos-easing="linear"
      data-aos-duration="1000"
    >
      <div
        className="w-full h-full relative flex items-center justify-center flex-col"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          zIndex: -1,
        }}
      >
        <div className="absolute inset-0 bg-gradient-to-t from-blue-900 to-transparent opacity-90"></div>

        <div className="relative z-10 w-full max-w-[1200px] mx-auto flex flex-col items-center px-4">
          {/* Responsive text for the title */}
          <h1 className="text-white text-center text-4xl sm:text-5xl md:text-[40px] font-bold mb-4">
            {texttitle}
          </h1>

          {/* Responsive container for the subtitle */}
          <div className="flex justify-center sm:space-x-10 md:space-x-52">
            <p className="text-white text-xl sm:text-2xl md:text-4xl font-bold">
              ~ Fiangonana Loterana Malagasy ~
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TitleFLM;
