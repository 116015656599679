import "./App.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import DynamicTitle from "./Components/DynamicTitle";
import Home from "./views/Home";
import Event from "./views/Event";
import About from "./views/About";
import Study from "./views/Study";
import Localization from "./views/Localization";
import Help from "./views/Help";
import NotFound from "./views/NotFound";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function App() {
  const location = useLocation();

  const hideHeaderFooter = location.pathname === "/TsyMisy";
  useEffect(() => {
    AOS.init(); // Initialiser AOS
    AOS.refresh(); // Rafraîchir AOS si nécessaire
  }, []);
  return (
    <div className="App mx-auto">
      {!hideHeaderFooter && <Header />}
      {/* Composant qui gère les titres */}
      <DynamicTitle />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/FLM" element={<About />} />
        <Route path="/Hetsika" element={<Event />} />
        <Route path="/Fampianarana" element={<Study />} />
        <Route path="/Localisation" element={<Localization />} />
        <Route path="/Fanampiana" element={<Help />} />
        <Route path="/TsyMisy" element={<NotFound />} />

        {/* Catch-all route pour rediriger vers NotFound si l'URL n'existe pas*/}
        <Route path="*" element={<Navigate to="/TsyMisy" />} />
      </Routes>

      {/* Affiche Footer seulement si hideHeaderFooter est faux */}
      {!hideHeaderFooter && <Footer />}
    </div>
  );
}

export default App;
