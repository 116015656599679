import React from "react";
import TitleFLM from "../Components/FLM/Title";
import EventCard from "../Components/EventCard";

function Event() {
  return (
    <div>
      <TitleFLM texttitle="Hetsika sy Tranga" heightTitle="400px" />
      <EventCard />
    </div>
  );
}

export default Event;
