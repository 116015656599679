import { Close, Menu, SearchOutlined } from "@mui/icons-material";
import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../assets/images/Logos/Logo.png";
import ScrollToTop from "./ScrollTop";
import Social from "./Social";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const getNavLinkClass = (isActive) => {
    return isActive
      ? "text-yellow-300 font-bold md:pr-8 lg:pr-10"
      : "text-blue-900 font-bold hover:text-yellow-300 transition-colors duration-200 md:pr-8 lg:pr-10";
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="relative">
      {/* Social section */}
      <div className="hidden md:block">
        <Social />
      </div>

      {/* Main header content */}
      <div className="container mx-auto px-4 md:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row justify-between items-center py-4">
          {/* Logo section */}
          <div className="md:w-auto flex md:justify-between md:items-center ml-7">
            <Link to="/">
              <img
                src={Logo}
                alt="Logo"
                className="h-20 w-20 md:h-20 md:w-20 lg:h-16 lg:w-16"
              />
            </Link>

            {/* Mobile menu button */}
            <button
              className="md:hidden p-2 text-blue-900"
              onClick={toggleMenu}
            >
              {isMenuOpen ? <Close /> : <Menu />}
            </button>
          </div>

          {/* Navigation menu */}
          <nav className={`${isMenuOpen ? "block" : "hidden"} md:block pb-4`}>
            <ul className="flex flex-col md:flex-row md:justify-end space-y-4 md:space-y-0 md:space-x-4 lg:space-x-6">
              <li>
                <NavLink
                  to="/"
                  className={({ isActive }) => getNavLinkClass(isActive)}
                >
                  Fandraisana
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/FLM"
                  className={({ isActive }) => getNavLinkClass(isActive)}
                >
                  FLM
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/Fampianarana"
                  className={({ isActive }) => getNavLinkClass(isActive)}
                >
                  Fampianarana
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/Hetsika"
                  className={({ isActive }) => getNavLinkClass(isActive)}
                >
                  Hetsika
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/Localisation"
                  className={({ isActive }) => getNavLinkClass(isActive)}
                >
                  Teolojiana
                </NavLink>
              </li>
            </ul>
          </nav>

          {/* Search section */}
          <div className="w-full md:w-auto mt-4 md:mt-0">
            {/* <h1 className="text-blue-900 text-lg md:text-xl text-center md:text-left">
              Hitady ny Fiangonana akaiky anao...
            </h1> */}
            <div className="flex justify-center my-4">
              <div className="relative w-full max-w-sm md:max-w-md lg:max-w-lg">
                <input
                  type="search"
                  placeholder="Sorato ny toerana misy anao..."
                  className="w-full p-3 pr-16 pl-4 border border-blue-900 bg-blue-50 rounded-full drop-shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button className="absolute right-1 top-1/2 transform -translate-y-1/2 bg-blue-900 text-white rounded-full px-4 py-2">
                  <SearchOutlined />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop />
    </header>
  );
}

export default Header;
