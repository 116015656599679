import React from "react";
import HDR from "../../assets/images/Logos/pikaso_edit 1.png";

function President() {
  return (
    <div className="max-w-6xl mx-auto text-white text-center flex flex-col md:flex-row">
      {/* Image responsive */}
      <div
        className="w-full md:w-1/4 px-4 md:px-6 flex justify-center items-center mb-6 md:mb-0"
        data-aos="flip-left"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="2000"
      >
        <img
          src={HDR}
          className="max-w-full h-64 md:h-auto object-contain"
          alt="President"
        />
      </div>

      {/* Texte responsive */}
      <div
        className="w-full md:w-3/4 bg-gray-50 px-6 py-10 flex flex-col justify-center"
        // data-aos="flip-right"
        // data-aos-easing="ease-out-cubic"
        // data-aos-duration="2000"
      >
        <h2 className="text-black text-2xl md:text-4xl font-bold mb-6 md:mb-10 text-left">
          Mots du Président
        </h2>
        <p className="text-black text-lg md:text-xl text-justify">
          Paoly APostoly amin'izao fanevantsika izao doa manatintrantitra fa
          mahatoky amin'ny teny fikasany Andriamanitra, ka tsy mivadika
          amin'ireo olona nofidiny sy nanaovany fanekena. Amin'izany
          Andriamanitra dia manome antoka fa hanatanteraka izay iantsoany
          antsika Izy ka tsy hamela antsika hiatrika irery ireo fanamin'ny
          fivoaran'ny asa misona eto amin'izao tontolo izao. Azaianteherana sy
          azo itokisana koa Andriamanitra rehefa mamita izay natombony ka
          maniraka antsika eto amin'izao tontolo izao ho vavolombelona hanambara
          ny famonjeny. Mahatoky amin'ny fanoloran-tenany ho antsika amin'ny
          alalan'i Jesosy Kristy Izy. Izany Andriamanitra mahatoky izany koa no
          manohana antsika taranaka ankehitriny ny asa izany nankininy amintsika
          sy iantsoanyantsika hatoky ny fitondany antsika na dia mafy sy sarotra
          aza ny asa omeny antsika. Misaotra an'Andriamanitra lehibe isika noho
          ny fitantanany ny fiainantsika ka anavaozany isan'andro ny fahasoavana
          izay omeny antsika ka mbola ahafahantsika mihaona am-pifaliana
          amin'izao tranokala ny foibe FLM izao. Miarahaba antsika rehetra tonga
          soa eto amin'ny tranokalan'ny FOIBE.
        </p>
        <p className="font-semibold text-black text-left mt-4 md:mt-8">
          ~ Rev Dr RAKOTOZAFY Denis
        </p>
      </div>
    </div>
  );
}

export default President;
