import React, { useEffect, useState } from "react";
import backgroundImage from "../../assets/images/BG/BG.png";
import { getNextSundayPerikopa } from "../../services/bdd";

const formatMalagasyDate = (dateStr) => {
  const [day, month, year] = dateStr.split("/");
  const months = [
    "Janoary",
    "Febroary",
    "Martsa",
    "Aprily",
    "Mey",
    "Jona",
    "Jolay",
    "Aogositra",
    "Septambra",
    "Oktobra",
    "Novambra",
    "Desambra",
  ];

  const monthName = months[parseInt(month, 10) - 1];
  return `${day} ${monthName} ${year}`;
};

function SliderTwo() {
  const [perikopa, setPerikopa] = useState(null);

  useEffect(() => {
    const fetchPerikopa = async () => {
      try {
        const sermonForToday = await getNextSundayPerikopa();
        setPerikopa(sermonForToday);
      } catch (error) {
        console.error("Erreur lors de la récupération du sermon :", error);
      }
    };

    fetchPerikopa();
  }, []);

  return (
    <div className="container max-w-7xl mx-auto px-4 sm:px-8 lg:px-24 py-10">
      <div className="relative z-10 w-full flex flex-col items-center text-white text-center">
        <h1 className="text-3xl sm:text-4xl md:text-3xl font-bold mb-10">
          PERIKOPA
        </h1>

        {perikopa ? (
          <>
            <p className="text-lg sm:text-2xl font-bold">
              {formatMalagasyDate(perikopa.date)}
            </p>
            <p className="text-lg sm:text-2xl font-bold">{perikopa.nom}</p>
            <p className="mt-6 sm:mt-10 font-semibold text-justify px-4 sm:px-8 lg:px-24 text-base sm:text-xl">
              {perikopa.dsc_tt || "Alahady tsy misy ny perikopa."}
            </p>

            <div className="w-full px-4 sm:px-8 space-y-8 mt-10 sm:mt-16 font-bold text-center text-base sm:text-lg">
              <p>Testamenta taloha : {perikopa.tt}</p>
              <p>Epistily : {perikopa.ep}</p>
              <p>Filazantsara : {perikopa.tv}</p>
              <p>Toriteny : {perikopa.trt}</p>
            </div>
          </>
        ) : (
          <p className="text-lg sm:text-xl">Tsy misy ny perikopa</p>
        )}
      </div>
    </div>
  );
}

export default SliderTwo;
