import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

function DynamicTitle() {
  const location = useLocation();

  const getTitle = (path) => {
    switch (path) {
      case "/":
        return "FLM-Foibe - Fandraisana";
      case "/FLM":
        return "FLM-Foibe - FLM";
      case "/Fampianarana":
        return "FLM-Foibe - Fampianarana";
      case "/Hetsika":
        return "FLM-Foibe - Hetsika";
      case "/Localisation":
        return "FLM-Foibe - Teolojiana";
      case "/Fanampiana":
        return "FLM-Foibe - Fanampiana";
      case "/TsyMisy":
        return "FLM-Foibe - TsyMisy";
      default:
        return "FLM-Foibe";
    }
  };

  return (
    <Helmet>
      <title>{getTitle(location.pathname)}</title>
    </Helmet>
  );
}

export default DynamicTitle;
