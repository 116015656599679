import axiosConfig from "./axiosConfig";
import axiosConfigFichier from "./axiosConfigFichier";



export const getAllFampianarana = async () => {
    try{
        const response = await axiosConfig.get('enseignement/getAll');
        return response.data;

    }catch (e) {
        console.error('Erreur lors de la récupération des fampianarana', e);
        throw e;
    }
};

export const getAllEvenements = async () => {
    try{
        const response = await axiosConfig.get('/evenement/getAll');
        return response.data;

    }catch (e) {
        console.error('Erreur lors de la récupération des evenements', e);
        throw e;
    }
}

export const getAllPastera = async () => {
    try{
        const response = await axiosConfig.get('pasteurs/getAll');
        return response.data;

    }catch (e) {
        console.error('Erreur lors de la récupération des pastera', e);
        throw e;
    }
};

export const getAllCount = async () => {
    try{
        const response = await axiosConfig.get('getAllCount');
        return response.data;

    }catch (e) {
        console.error('Erreur lors de la sélection des fiangonana', e);
        throw e;
    }
};

export const fetchFile = async (fileName) => {
    try {
        const response = await axiosConfigFichier.get(`/storage/${fileName}`, {
            responseType: 'blob'
        });

        if (response.status !== 200) {
            throw new Error("Erreur lors du téléchargement du fichier");
        }

        const fileUrl = URL.createObjectURL(response.data);
        return fileUrl;
    } catch (error) {
        console.error("Erreur:", error);
        return null;
    }
};