import React from 'react';
import Logo from "../assets/images/Logos/Logo.png";
import { NavLink } from "react-router-dom";

function AboutHome() {
    return (
        <div className="max-w-8xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 mt-10 md:mt-20 px-8 md:px-16" data-aos="fade-up-right" data-aos-duration="1000">
            {/* Première section */}
            <div className="flex justify-center">
                <img src={Logo} alt="Logo" className="h-60 w-60 sm:h-80 sm:w-80 object-cover"/>
            </div>

            {/* Deuxième section */}
            <div className="md:col-span-2">
                <p className="font-bold text-2xl sm:text-4xl">
                    Ny hevitry ny sary sy ny loko eo amin’ny <span className="text-blue-600">Raozin’i LOTERA</span>
                </p>
                <p className="mt-6 sm:mt-10 mb-6 sm:mb-10">
                    Ny hazo fijaliana mainty manambara ny fijaliana sy ny fahafatesan’i Jesosy ho famonjena ny olona. Ny
                    sary fo miloko lena dia isika olombelona manaiky an’i Jesosy ho mpamonjy...
                </p>
                <NavLink to="/FLM" className="bg-blue-600 text-white rounded-full py-2 px-4 font-bold">
                    Ny tantaran'ny FLM
                </NavLink>

                <h1 className="text-blue-600 text-2xl sm:text-3xl font-bold mt-8 sm:mt-10">NY LITORJIA</h1>
                <p className="mt-6 sm:mt-10 mb-6 sm:mb-10">
                    Heverina ho isan’ny maha Loterana koa ny Litorjia. Tena tsy mahazo aina...
                </p>
                <NavLink to="/FLM" className="bg-blue-600 text-white rounded-full py-2 px-4 font-bold">
                    Ny litorjian'ny FLM
                </NavLink>
            </div>
        </div>
    );
}

export default AboutHome;
