import React from "react";
import TitleFLM from "../Components/FLM/Title";
import CardGrid from "../Components/CardGrid";

function Localization() {
  return (
    <div>
      <TitleFLM texttitle="Ireo Teolojiana" heightTitle="400px" />
      <CardGrid />
    </div>
  );
}

export default Localization;
