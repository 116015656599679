import React, { useEffect, useState } from "react";
import { getSermonForToday } from "../../services/bdd";

function SliderThree() {
  const [anio, setAnio] = useState(null);

  // Récupérer la date actuelle
  const today = new Date();
  const year = today.getFullYear();

  useEffect(() => {
    const fetchSermon = async () => {
      try {
        const sermonForToday = await getSermonForToday();
        setAnio(sermonForToday);
      } catch (error) {
        console.error("Erreur lors de la récupération du sermon :", error);
      }
    };

    fetchSermon();
  }, []);

  return (
    <div className="container max-w-7xl mx-auto px-4 sm:px-8 lg:px-24 py-10">
      <div className="relative z-10 w-full flex flex-col items-center text-center">
        <h1 className="text-white text-3xl sm:text-4xl md:text-3xl font-bold mb-5">
          VATSY ANDALANA
        </h1>
        <p className="text-white font-bold italic text-lg sm:text-1xl mb-4">
          {anio ? `Anio ${anio.date} ${year}` : "Tsisy vatsy andalana anio"}
        </p>
        <div className="w-full space-y-6">
          {anio ? (
            <div
              dangerouslySetInnerHTML={{ __html: anio.text }}
              className="text-white text-base sm:text-lg lg:text-xl text-justify"
            />
          ) : (
            <p className="text-white text-lg sm:text-xl text-center">
              Tsisy vatsy andalana anio
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default SliderThree;
