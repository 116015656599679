import React, { useEffect, useState } from "react";
import { SupervisedUserCircleOutlined } from "@mui/icons-material";
import DonutSmallOutlinedIcon from "@mui/icons-material/DonutSmallOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import ChurchOutlinedIcon from "@mui/icons-material/ChurchOutlined";
import { getAllCount } from "../../apiConfig/services";

function AnimatedCounter({ target }) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count < target) {
      const increment = Math.ceil(target / 100); // Ajustez l'incrément pour contrôler la vitesse de l'animation
      const timer = setInterval(() => {
        setCount((prevCount) => {
          const nextCount = prevCount + increment;
          return nextCount >= target ? target : nextCount;
        });
      }, 10); // Ajustez la durée pour une animation plus rapide ou plus lente

      return () => clearInterval(timer);
    }
  }, [count, target]);

  return <span>{count.toLocaleString()}</span>;
}

function SliderOne() {
  const [count, setCount] = useState({
    countPastora: 0,
    countKristiana: 0,
    countSynoda: 0,
    countFitandremana: 0,
  });

  const fetchCount = async () => {
    try {
      const response = await getAllCount();
      setCount(response);
    } catch (error) {
      console.error("Erreur lors de la sélection des fiangonana", error);
    }
  };

  useEffect(() => {
    fetchCount();
  }, []);

  return (
    <div className="container max-w-7xl px-8 md:px-16 mx-auto">
      <div className="relative z-10 w-full flex flex-col items-center text-center">
        <h3 className="text-white text-3xl sm:text-4xl md:text-3xl font-bold mb-8">
          FIANGONANA LOTERANA MALAGASY
          <br />
          TONGA SOA
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-4">
          <p className="text-white text-xl sm:text-2xl md:text-2xl font-bold">
            Ny Marina amin’ny Finoana no ho Velona. Romana 1:17
          </p>
          <ul className="text-white text-base sm:text-lg md:text-xl font-semibold">
            <li> &gt; Mitory ny filazantsaran’i Jesosy Kristy</li>
            <li> &gt; Mijoro ny vavolombelon’i Jesosy Kristy</li>
            <li> &gt; Mandroso amin’ny asa tsara</li>
            <li> &gt; Mampandroso ny olona rehetra sy ny olona manontolo</li>
          </ul>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-4 gap-8 mt-8">
          <div className="flex flex-col items-center">
            <SupervisedUserCircleOutlined
              style={{ fontSize: "40px", color: "white" }}
            />
            <span className="text-white text-2xl sm:text-3xl">
              <AnimatedCounter target={count.countPastora} />
            </span>
            <hr className="border-white mb-2" />
            <span className="text-white text-base sm:text-lg text-center">
              Pastora sy Teolojiana
            </span>
          </div>
          <div className="flex flex-col items-center">
            <DonutSmallOutlinedIcon
              style={{ fontSize: "40px", color: "white" }}
            />
            <span className="text-white text-2xl sm:text-3xl">
              <AnimatedCounter target={"4000000"} />
            </span>
            <hr className="border-white mb-2" />
            <span className="text-white text-base sm:text-lg text-center">
              Kristiana
            </span>
          </div>
          <div className="flex flex-col items-center">
            <MapOutlinedIcon style={{ fontSize: "40px", color: "white" }} />
            <span className="text-white text-2xl sm:text-3xl">
              <AnimatedCounter target={count.countSynoda} />
            </span>
            <hr className="border-white mb-2" />
            <span className="text-white text-base sm:text-lg text-center">
              Synoda
            </span>
          </div>
          <div className="flex flex-col items-center">
            <ChurchOutlinedIcon style={{ fontSize: "40px", color: "white" }} />
            <span className="text-white text-2xl sm:text-3xl">
              <AnimatedCounter target={count.countFitandremana} />
            </span>
            <hr className="border-white mb-2" />
            <span className="text-white text-base sm:text-lg text-center">
              Fiangonana
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SliderOne;
