import React, { useEffect, useRef, useState } from "react";
import { getAllEvenements } from "../apiConfig/services";
import ImageEvent from "../assets/images/others/Frame 1000001754.png";

// Fonction pour formater la date
const formatMonth = (dateString) => {
  const [day, month] = dateString.split(" ");
  const truncatedMonth = month.substring(0, 4); // Prendre les 4 premières lettres du mois
  return { day, truncatedMonth };
};

const EventCard = () => {
  const [events, setEvents] = useState([]); // État pour stocker les événements
  const [selectedYear, setSelectedYear] = useState("2024"); // État pour l'année sélectionnée
  const [expandedIndex, setExpandedIndex] = useState(null); // État pour gérer l'expansion des événements
  const eventRefs = useRef([]); // Références pour chaque événement

  useEffect(() => {
    fetchData(); // Appel à la fonction de récupération des données
  }, []);

  // Fonction pour récupérer les données des événements
  const fetchData = async () => {
    try {
      const events = await getAllEvenements();
      const newData = events.map((event) => ({
        id: event.id,
        title: event.titre,
        date: new Date(event.date).toLocaleDateString("fr-FR", {
          day: "numeric",
          month: "long",
        }),
        year: event.date.substring(0, 4), // Conserver l'année
        description: event.description,
        image: event.image,
        location: event.location, // Assurez-vous que la propriété 'location' existe
      }));
      setEvents(newData); // Mettre à jour l'état avec les nouveaux événements
    } catch (error) {
      console.error("Erreur lors de la création des données:", error);
    }
  };

  // Filtrage des événements en fonction de l'année sélectionnée
  const filteredEvents = events.filter((event) => event.year === selectedYear);
  const visibleEvents = filteredEvents.slice(0, 4); // Limiter à 4 événements visibles

  // Fonction pour gérer l'expansion des événements
  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
    if (eventRefs.current[index]) {
      eventRefs.current[index].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <div className="container max-w-6xl mx-auto p-4 mt-10">
      {/* Années */}
      <div className="flex justify-around text-center mb-8">
        {[...new Set(events.map((event) => event.year))]
          .sort((a, b) => a - b) // Trier les années en ordre croissant
          .map((year) => (
            <button
              key={year}
              onClick={() => setSelectedYear(year)}
              className={`text-xl font-semibold transition-colors duration-300 ${
                selectedYear === year
                  ? "text-blue-600"
                  : "text-gray-400 hover:text-blue-600"
              }`}
            >
              {year}
            </button>
          ))}
      </div>

      {/* Timeline des événements */}
      <div className="flex space-x-4 container mx-auto px-4 md:px-6 lg:px-8">
        {/* Liste des événements */}
        <div
          className={`flex-1 ${
            filteredEvents.length > 4 ? "overflow-y-auto h-96" : ""
          }`}
        >
          {visibleEvents.map((event, index) => {
            const { day, truncatedMonth } = formatMonth(event.date); // Formatage de la date

            return (
              <div
                key={index}
                className="flex items-center mb-8"
                ref={(el) => (eventRefs.current[index] = el)} // Assigner la référence à chaque événement
              >
                <div className="flex-shrink-0">
                  {/* Date arrondie */}
                  <div className="text-xl text-blue-600 font-bold text-center border-2 border-blue-600 rounded-full w-24 h-24 flex flex-col justify-center items-center hover:bg-blue-600 hover:text-white hover:cursor-default duration-300">
                    <span>{day}</span> {/* Jour */}
                    <span className="capitalize">{truncatedMonth}</span>{" "}
                    {/* Mois (4 lettres) */}
                  </div>
                </div>
                <div className="ml-4">
                  <h3 className="text-2xl font-semibold">{event.title}</h3>
                  <p className="text-white bg-blue-600 w-fit px-2 rounded-full my-4">
                    {event.location}
                  </p>

                  {/* Description tronquée */}
                  <p className="text-gray-500">
                    {expandedIndex === index
                      ? event.description
                      : `${event.description.substring(0, 100)}...`}
                  </p>

                  {/* Bouton pour voir plus ou moins */}
                  <button
                    onClick={() => toggleExpand(index)}
                    className="mt-2 text-blue-600 hover:text-blue-800 underline transition-colors duration-300"
                  >
                    {expandedIndex === index ? "Afenina" : "Aseho"}
                  </button>
                </div>
              </div>
            );
          })}
        </div>

        {/* Image du calendrier */}
        <div className="hidden xl:block w-1/3">
          <img
            src={ImageEvent}
            alt="Calendrier"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default EventCard;
