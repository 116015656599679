import React, { useState, useEffect } from "react";
import SliderOne from "./Sliders/SliderOne";
import SliderTwo from "./Sliders/SliderTwo";
import SliderThree from "./Sliders/SliderThree";
import backgroundImage from "../assets/images/BG/FLMTitle.png";
import IconButton from "@mui/material/IconButton";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

function Title() {
  const [activeSlide, setActiveSlide] = useState(0);
  const slides = [<SliderOne />, <SliderTwo />, <SliderThree />];

  // Passe automatiquement à la prochaine diapositive
  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 60000); // Change de diapositive toutes les 60 secondes

    return () => clearInterval(intervalId); // Efface l'intervalle lors du démontage
  }, [slides.length]);

  // Passe à la diapositive précédente
  const handlePrev = () => {
    setActiveSlide((prevSlide) =>
      prevSlide === 0 ? slides.length - 1 : prevSlide - 1
    );
  };

  // Passe à la diapositive suivante
  const handleNext = () => {
    setActiveSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  return (
    <div className="relative">
      {/* Diapositive active */}
      <div
        className="w-full h-full flex items-center justify-center flex-col py-6"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "70vh",
          position: "relative",
        }}
      >
        <div className="absolute inset-0 bg-gradient-to-t from-blue-900 to-transparent opacity-100"></div>
        {slides[activeSlide]}

        {/* Bouton de navigation - Précédent */}
        <div className="absolute top-1/2 left-4 transform -translate-y-1/2 z-10">
          <IconButton onClick={handlePrev} style={{ color: "white" }}>
            <NavigateBeforeIcon fontSize="large" />
          </IconButton>
        </div>

        {/* Bouton de navigation - Suivant */}
        <div className="absolute top-1/2 right-4 transform -translate-y-1/2 z-10">
          <IconButton onClick={handleNext} style={{ color: "white" }}>
            <NavigateNextIcon fontSize="large" />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

export default Title;
