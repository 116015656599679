import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import OndemandVideoRoundedIcon from "@mui/icons-material/OndemandVideoRounded";
import VolumeUpRoundedIcon from "@mui/icons-material/VolumeUpRounded";
import React, { useEffect, useState } from "react";
import { fetchFile, getAllFampianarana } from "../apiConfig/services";
import cover1 from "../assets/images/Logos/Logo.png";
import ModalDetails from "./ModalDetails";
import TeachingSidebar from "./TeachingSidebar";

function CardFampianarana() {
  const [fampianarana, setFampianarana] = useState([]);
  const [filteredFampianarana, setFilteredFampianarana] = useState([]);
  const [types, setTypes] = useState([]);
  const [years, setYears] = useState([]);
  const [selectedType, setSelectedType] = useState("tout");
  const [covers, setCovers] = useState({});
  const [isModalDetails, setModalDetails] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);

  useEffect(() => {
    const fetchFampianarana = async () => {
      try {
        const fampianaranadata = await getAllFampianarana();
        const uniqueTypes = [
          ...new Set(fampianaranadata.map((item) => item.type)),
        ];
        const uniqueYears = [
          ...new Set(
            fampianaranadata.map((item) =>
              new Date(item.date_publication).getFullYear()
            )
          ),
        ];

        setTypes(uniqueTypes);
        setYears(uniqueYears);

        const coverPromises = fampianaranadata.map(async (book) => {
          if (book.image_representative) {
            const coverUrl = await fetchFile(book.image_representative);
            return { id: book.id, coverUrl };
          }
          return { id: book.id, coverUrl: null };
        });

        const coversData = await Promise.all(coverPromises);
        const coversDict = coversData.reduce((acc, { id, coverUrl }) => {
          acc[id] = coverUrl;
          return acc;
        }, {});

        setCovers(coversDict);
        setFampianarana(fampianaranadata);
        setFilteredFampianarana(fampianaranadata);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des données fampianarana:",
          error
        );
      }
    };
    fetchFampianarana();
  }, []);

  useEffect(() => {
    filterFampianarana();
  }, [selectedType, fampianarana]);

  const filterFampianarana = () => {
    let filtered = [...fampianarana];

    if (selectedType && selectedType !== "tout") {
      filtered = filtered.filter(
        (item) => item.type.toLowerCase() === selectedType
      );
    }

    setFilteredFampianarana(filtered);
  };

  const handleFilterChange = (type) => {
    setSelectedType(type);
  };

  const handleView = (course) => {
    if (course.type === "audio" || course.type === "video") {
      window.open(course.lien_fichier, "_blank");
    } else {
      setSelectedCourse(course);
      setModalDetails(true);
    }
  };

  return (
    <div className="max-w-6xl mx-auto flex flex-wrap lg:flex-nowrap gap-8 p-4 lg:p-6">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-6 w-full lg:w-3/4">
        {filteredFampianarana.length > 0 ? (
          filteredFampianarana.map((course, index) => (
            <div
              key={index}
              className="card-cours bg-white shadow-lg rounded-xl overflow-hidden transform transition duration-300 hover:scale-105 hover:shadow-xl relative hover:cursor-pointer"
              data-aos="fade-up"
              onClick={() => handleView(course)}
            >
              <div className="relative h-48 rounded-xl overflow-hidden">
                {covers[course.id] && (
                  <img
                    src={covers[course.id]}
                    alt={course.title}
                    className="w-full h-full object-contain rounded-xl"
                  />
                )}
                <img
                  src={cover1}
                  alt={course.title}
                  className="w-full h-full object-cover rounded-xl"
                />
              </div>
              <div className="p-4">
                <div className="flex items-center mb-2">
                  {course.type === "video" && (
                    <OndemandVideoRoundedIcon className="text-[#347F90] mr-2" />
                  )}
                  {course.type === "audio" && (
                    <VolumeUpRoundedIcon className="text-[#347F90] mr-2" />
                  )}
                  {course.type === "texte" && (
                    <MenuBookRoundedIcon className="text-[#347F90] mr-2" />
                  )}
                  <p className="text-[#347F90] inline-block py-1 px-3 text-sm md:text-md rounded-full">
                    {course.type}
                  </p>
                </div>
                <h2 className="text-lg md:text-xl font-semibold mb-2">
                  {course.titre}
                </h2>
                <p className="text-gray-600 mb-1">Auteur : {course.auteur}</p>
                <p className="text-gray-400 text-sm">
                  Date de publication : {course.date_publication}
                </p>
              </div>
              <div className="absolute bottom-4 right-4">
                <DownloadRoundedIcon className="text-[#adadad]" />
              </div>
            </div>
          ))
        ) : (
          <div className="col-span-full text-center text-gray-500">
            Tsy misy fampianarana
          </div>
        )}
      </div>

      <div className="w-full lg:w-1/4" data-aos="fade-up" data-aos-delay="100">
        <TeachingSidebar types={types} onFilterChange={handleFilterChange} />
      </div>

      {isModalDetails && selectedCourse && (
        <ModalDetails
          course={selectedCourse}
          onClose={() => setModalDetails(false)}
        />
      )}
    </div>
  );
}

export default CardFampianarana;
