import React from 'react';
import { NavLink } from "react-router-dom";
import Calice from "../assets/images/Pub/Calice.jpg";
import Flms from "../assets/images/Pub/Flms.png";

function Pub() {
    return (
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-4 gap-8 mt-10 md:mt-20 px-8 md:px-16" data-aos="zoom-in-down" data-aos-duration="1000">
            {/* Première section */}
            <div>
                <p className="mb-4">
                    Koa ny finoana dia avy amin'ny tori-teny, ary ny tori-teny kosa avy amin'ny tenin'i Kristy. Romana 10:17...
                </p>
                <NavLink to="/Fampianarana" className="font-bold text-blue-600">
                    Hamaky azy rehetra
                </NavLink>
            </div>

            {/* Image */}
            <div className="flex justify-center">
                <img src={Calice} alt="Calice" className="h-60 w-60 sm:h-96 sm:w-96 object-contain" />
            </div>

            {/* Deuxième section */}
            <div>
                <p>
                    Ny fiangonana Loterana Malagasy izay manaraka ny rafi-pitondrana Synidaly, dia Fiangonana iray manerana...
                </p>
                <ul className="ml-6 list-disc mb-4">
                    <li>Fiangonana</li>
                    <li>Fitandremana</li>
                    <li>Fileovana</li>
                    <li>Synodam-Paritany</li>
                    <li>Synoda Lehibe</li>
                </ul>
                <NavLink to="/Fampianarana" className="font-bold text-blue-600">
                    Hamaky azy rehetra
                </NavLink>
            </div>

            {/* Image */}
            <div className="flex justify-center">
                <img src={Flms} alt="Flms" className="h-60 w-60 sm:h-96 sm:w-96 object-fill" />
            </div>
        </div>
    );
}

export default Pub;
