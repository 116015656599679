import React from "react";
import Person from "@mui/icons-material/Person"; // Assurez-vous que cette importation est correcte

const ModalTeolojianaDetails = ({ isOpen, onClose, details }) => {
  if (!isOpen) return null; // Ne pas rendre le modal s'il n'est pas ouvert

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="absolute inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>
      <div className="bg-white rounded-lg shadow-lg p-6 z-10 w-96 flex flex-col items-center">
        <div className="w-32 h-32 rounded-full border-4 border-gray-200 flex items-center justify-center bg-gray-100">
          <Person className="text-gray-400" style={{ fontSize: "3rem" }} />
        </div>
        <h2 className="text-2xl font-bold">{details.name}</h2>
        <p className="text-gray-700">
          <strong>Finday:</strong> {details.phone}
        </p>
        <p className="text-gray-700">
          <strong>Email:</strong> {details.email}
        </p>
        <p className="text-gray-700">
          <strong>Synaoda:</strong> {details.role}
        </p>
        <button
          onClick={onClose}
          className="mt-4 bg-blue-600 text-white font-bold py-1 px-4 rounded hover:bg-blue-700 transition duration-200"
        >
          Fermer
        </button>
      </div>
    </div>
  );
};

export default ModalTeolojianaDetails;
