import React from "react";
import {
  FacebookRounded,
  LocationOnRounded,
  EmailRounded,
  PlayArrowRounded,
} from "@mui/icons-material";
import Logo from "../assets/images/Logos/Logo.png";
import Logo_Rff from "../assets/images/Logos/Logo_Rff.png";

function Footer() {
  return (
    <div
      className="relative mx-auto max-w-7xl w-full py-5 mt-20 text-blue-900 border-t border-blue-900"
      data-aos="fade-down"
    >
      <div className="flex flex-col lg:flex-row justify-around items-center lg:items-start space-y-10 lg:space-y-0 px-8 md:px-16">
        <div className="flex flex-col items-center lg:items-start">
          <div className="flex space-x-4 mb-5">
            <img src={Logo} alt="Logo" className="h-32 w-32 lg:h-40 lg:w-40" />
            <img
              src={Logo_Rff}
              alt="Logo"
              className="h-32 w-32 lg:h-40 lg:w-40"
            />
          </div>
          <div className="relative m-2 border border-blue-900 rounded-full w-80 lg:w-96">
            <input
              type="text"
              placeholder="91.2MHz"
              disabled={true}
              className="bg-gray-50 w-full px-4 py-4 pr-12 rounded-full"
            />
            <a href="https://flm-rff.org/" target="_blank">
              <button className="absolute right-0 top-0 bottom-0 text-black px-5 py-3 bg-blue-900 rounded-r-full">
                <PlayArrowRounded className="text-white" />
              </button>
            </a>
          </div>
        </div>

        <div className="flex flex-col items-start lg:items-start text-left lg:text-left">
          <h2 className="text-2xl font-bold mb-4">Fifandraisana</h2>
          <p className="mb-3">
            Araho tsy tapaka ny vaovao eto amin'ny Fiangonana
          </p>
          <p className="mb-3 flex items-center justify-center lg:justify-start">
            <a
              href="https://maps.app.goo.gl/1cppiFEHuHKtuni16"
              target="_blank"
              rel="noopener noreferrer"
              className="transition duration-300 ease-in-out hover:text-blue-500"
            >
              <LocationOnRounded className="mr-2" />
              Rue RAKOTONIRINA Stanislas, Tananarive Madagascar, BP 100
            </a>
          </p>
          <p className="mb-3 flex items-start justify-center lg:justify-start">
            <a
              href="mailto:flm.foibe@gmail.com"
              className="transition duration-300 ease-in-out hover:text-blue-500"
            >
              <EmailRounded className="mr-2" />
              flm.foibe@gmail.com
            </a>
          </p>
          <a
            href="https://web.facebook.com/foibeflm/?locale=fr_FR&_rdc=1&_rdr"
            target="_blank"
            rel="noopener noreferrer"
            className="transition duration-300 ease-in-out hover:text-blue-500 flex items-center"
          >
            <FacebookRounded className="mr-2" />
            Nom Facebook
          </a>
        </div>

        <div className="flex flex-col items-start lg:items-start text-left lg:text-left">
          <h2 className="text-2xl font-bold mb-4">Afaka alaina</h2>
          <p className="text-sm">
            FANAPAHAN-KEVITRA KMSL 142 - SPAf (2259 téléchargements).pdf <br />
            FANDRAVONANA NY ATRIKASA NATAO TANY ANTSIRABE (3305
            téléchargements).pdf <br />
            HAFATRY NY FOIBE FLM (1337 téléchargements).pdf <br />
            Taratasy Pastoraly ho amin'ny fanohanana ny Pastoran'ny FLM (1444
            téléchargements).pdf <br />
            Tatitra KMSL 140, Ambatofinandrahana (1946 téléchargements).pdf{" "}
            <br />
            Tatitra ny voina mahatratra Ankaramalaza (1138 téléchargements).pdf{" "}
            <br />
            TATITRY NY KMSL 141 VONDROZO (1281 téléchargements).pdf <br />
            Tolotr'asa FOIBE FLM (945 téléchargements).pdf
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
