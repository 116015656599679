import React from 'react';
import { useNavigate } from 'react-router-dom';
import NotFoundImage from '../assets/images/NotFound/NF2.png'; // Import correct de l'image

function NotFound() {
    const navigate = useNavigate();

    const goToHome = () => {
        navigate('/');
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 text-center">
            <div className="text-6xl font-bold text-blue-500">Tsy hita ny pejy</div>

            <img
                src={NotFoundImage} // Utilisation correcte de la variable pour l'image
                alt="404 Not Found"
                className="mt-10 h-72 w-96"
            />

            <button
                onClick={goToHome}
                className="mt-8 px-6 py-3 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600 transition duration-300"
            >
                Miverina any amin'ny Fandraisana
            </button>
        </div>
    );
}

export default NotFound;
