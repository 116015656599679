import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import MailIcon from "@mui/icons-material/Mail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import RadioIcon from "@mui/icons-material/Radio";

function Social() {
  return (
    <div className="bg-blue-900 pl-28 pr-28 p-3 flex space-x-20">
      <a
        href="https://web.facebook.com/foibeflm/?locale=fr_FR&_rdc=1&_rdr"
        target="_blank"
        rel="noopener noreferrer"
        className="transition duration-300 ease-in-out hover:text-blue-500 flex items-center"
      >
        <FacebookIcon className="text-white" />
      </a>
      <a
        href="mailto:flm.foibe@gmail.com"
        className="transition duration-300 ease-in-out hover:text-blue-500"
      >
        <MailIcon className="text-white" />
      </a>
      <a
        href="https://maps.app.goo.gl/1cppiFEHuHKtuni16"
        target="_blank"
        rel="noopener noreferrer"
        className="transition duration-300 ease-in-out hover:text-blue-500"
      >
        <LocationOnIcon className="text-white" />
      </a>
      <a
        href="https://flm-rff.org/"
        target="_blank"
        rel="noopener noreferrer"
        className="transition duration-300 ease-in-out hover:text-blue-500"
      >
        <RadioIcon className="text-white" />
      </a>
    </div>
  );
}

export default Social;
