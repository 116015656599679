import React, { useState } from "react";
import { Filter1Rounded, MenuBookRounded } from "@mui/icons-material";
// import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

const TeachingSidebar = ({ types, onFilterChange }) => {
  const [selectedFilter, setSelectedFilter] = useState("tout");

  const handleFilterChange = (type) => {
    setSelectedFilter(type.toLowerCase());
    onFilterChange(type.toLowerCase());
  };

  return (
    <div className="w-full bg-white rounded-xl shadow-lg p-6">
      {/* Header */}
      <div className="border-b pb-4 mb-6">
        <h2 className="text-xl font-bold text-gray-800 flex items-center gap-2">
          <MenuBookRounded className="w-5 h-5 text-[#347F90]" />
          Tahiry ny Fampianarana
        </h2>
      </div>

      {/* Filters */}
      <div className="mb-6">
        <div className="flex items-center gap-2 mb-4 text-gray-700 font-medium">
          <Filter1Rounded className="w-4 h-4" />
          <span>Sivana isaky ny sokajy</span>
        </div>
        <div className="space-y-2">
          {["Tout", ...types].map((type) => (
            <button
              key={type}
              onClick={() => handleFilterChange(type)}
              className={`w-full text-left px-4 py-2 rounded-lg transition-colors ${
                selectedFilter === type.toLowerCase()
                  ? "bg-[#347F90] text-white"
                  : "text-gray-600 hover:bg-gray-100"
              }`}
            >
              {type}
            </button>
          ))}
        </div>
      </div>

      {/* Download All Button 
      <button className="w-full mt-6 flex items-center justify-center gap-2 bg-[#347F90] text-white py-3 rounded-lg hover:bg-[#2a6571] transition-colors">
        <DownloadRoundedIcon className="w-4 h-4" />
        <span>Télécharger tout</span>
          </button>
          */}
    </div>
  );
};

export default TeachingSidebar;
