import React from "react";
import { Person } from "@mui/icons-material";

const CardTeolojianina = ({ name, phone, role, email, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="rounded-2xl shadow-lg p-6 w-96 h-60 relative mb-12 bg-white cursor-pointer"
    >
      {/* Icône circulaire */}
      <div className="absolute -top-16 left-1/2 transform -translate-x-1/2">
        <div className="w-32 h-32 rounded-full border-4 border-gray-200 flex items-center justify-center bg-gray-100">
          <Person className="text-gray-400" style={{ fontSize: "3rem" }} />
        </div>
      </div>

      {/* Contenu de la carte */}
      <div className="mt-12 text-center space-y-3">
        <h2 className="text-2xl font-bold text-blue-600">{name}</h2>
        <button className="bg-blue-600 text-white font-bold py-1 px-4 rounded-full hover:bg-blue-700 transition duration-200">
          {role}
        </button>
        <p className="text-gray-700 text-lg font-medium">{phone}</p>
        <p className="text-gray-500">{email}</p>
      </div>
    </div>
  );
};

export default CardTeolojianina;
