import {
  ArrowDownward,
  ArrowUpwardRounded,
  FilterList,
  SearchOutlined,
  ChevronLeft,
  ChevronRight,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { getAllPastera } from "../apiConfig/services";
import CardTeolojianina from "./CardTeolojianina";
import ModalTeolojianaDetails from "./ModalTeolojianaDetails";

const CardGrid = () => {
  const [filter, setFilter] = useState("");
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [isFilterExpanded, setIsFilterExpanded] = useState(true);
  const [cardsData, setCardsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState(null);
  const cardsPerPage = 10;

  const fetchData = async () => {
    const url = "https://randomuser.me/api/portraits/men/32.jpg";
    try {
      const response = await getAllPastera();
      const data = response.map((pastera) => ({
        name: pastera.name,
        phone: pastera.tel_pas,
        role: pastera.role,
        email: pastera.email_pas,
        image: url,
      }));
      setCardsData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Reset to first page when filter changes
  useEffect(() => {
    setCurrentPage(1);
  }, [filter, selectedRoles]);

  const uniqueRoles = [...new Set(cardsData.map((card) => card.role))];

  const handleRoleChange = (role) => {
    setSelectedRoles((prevRoles) =>
      prevRoles.includes(role)
        ? prevRoles.filter((r) => r !== role)
        : [...prevRoles, role]
    );
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const filteredCards = cardsData.filter((card) => {
    const matchesNameOrRole =
      card.name.toLowerCase().includes(filter.toLowerCase()) ||
      card.role.toLowerCase().includes(filter.toLowerCase());
    const matchesRoleFilter =
      selectedRoles.length === 0 || selectedRoles.includes(card.role);
    return matchesNameOrRole && matchesRoleFilter;
  });

  const totalPages = Math.ceil(filteredCards.length / cardsPerPage);
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = filteredCards.slice(indexOfFirstCard, indexOfLastCard);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleCardClick = (card) => {
    setSelectedDetails(card);
    setIsModalOpen(true);
  };

  const renderPagination = () => {
    const maxVisiblePages = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);
    
    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    return (
      <div className="flex items-center justify-center mt-8 gap-2">
        <button
          onClick={() => currentPage > 1 && paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className={`p-2 rounded-lg ${
            currentPage === 1
              ? "bg-gray-100 text-gray-400 cursor-not-allowed"
              : "bg-white text-gray-700 hover:bg-blue-50 hover:text-blue-600"
          } border transition-colors duration-200`}
          aria-label="Previous page"
        >
          <ChevronLeft className="w-5 h-5" />
        </button>

        {startPage > 1 && (
          <>
            <button
              onClick={() => paginate(1)}
              className={`h-10 w-10 rounded-lg border ${
                currentPage === 1
                  ? "bg-blue-600 text-white"
                  : "bg-white text-gray-700 hover:bg-blue-50 hover:text-blue-600"
              } transition-colors duration-200`}
            >
              1
            </button>
            {startPage > 2 && (
              <span className="px-2 text-gray-500">...</span>
            )}
          </>
        )}

        {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map(
          (page) => (
            <button
              key={page}
              onClick={() => paginate(page)}
              className={`h-10 w-10 rounded-lg border ${
                currentPage === page
                  ? "bg-blue-600 text-white"
                  : "bg-white text-gray-700 hover:bg-blue-50 hover:text-blue-600"
              } transition-colors duration-200`}
            >
              {page}
            </button>
          )
        )}

        {endPage < totalPages && (
          <>
            {endPage < totalPages - 1 && (
              <span className="px-2 text-gray-500">...</span>
            )}
            <button
              onClick={() => paginate(totalPages)}
              className={`h-10 w-10 rounded-lg border ${
                currentPage === totalPages
                  ? "bg-blue-600 text-white"
                  : "bg-white text-gray-700 hover:bg-blue-50 hover:text-blue-600"
              } transition-colors duration-200`}
            >
              {totalPages}
            </button>
          </>
        )}

        <button
          onClick={() => currentPage < totalPages && paginate(currentPage + 1)}
          disabled={currentPage === totalPages}
          className={`p-2 rounded-lg ${
            currentPage === totalPages
              ? "bg-gray-100 text-gray-400 cursor-not-allowed"
              : "bg-white text-gray-700 hover:bg-blue-50 hover:text-blue-600"
          } border transition-colors duration-200`}
          aria-label="Next page"
        >
          <ChevronRight className="w-5 h-5" />
        </button>
      </div>
    );
  };

  return (
    <div className="container max-w-6xl mx-auto py-8 px-4 mt-16">
      <div className="flex space-x-8">
        <div className="lg:w-1/4 w-full max-w-xs">
          <div className="rounded-xl bg-white p-6 shadow-lg">
            <div className="flex items-center justify-between">
              <h3 className="text-xl font-bold text-gray-800">Sivana</h3>
              <button
                onClick={() => setIsFilterExpanded(!isFilterExpanded)}
                className="lg:hidden"
              >
                {isFilterExpanded ? <ArrowUpwardRounded /> : <ArrowDownward />}
              </button>
            </div>
            <div
              className={`mt-4 space-y-4 transition-all duration-300 ${
                isFilterExpanded ? "block" : "hidden lg:block"
              }`}
            >
              <div className="relative">
                <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400">
                  <SearchOutlined />
                </span>
                <input
                  type="search"
                  placeholder="Fikarohana synoda..."
                  value={filter}
                  onChange={handleFilterChange}
                  className="w-full rounded-lg border border-gray-200 bg-gray-50 py-2 pl-10 pr-4 text-gray-700 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-2 focus:ring-blue-500/20"
                />
              </div>

              <div className="space-y-2">
                <h4 className="font-semibold text-gray-700">
                  <div className="flex items-center space-x-2">
                    <FilterList />
                    <span>Sivanina araka ny Synoda</span>
                  </div>
                </h4>
                {uniqueRoles.map((role) => (
                  <label
                    key={role}
                    className="group flex cursor-pointer items-center rounded-lg p-2 transition-colors hover:bg-gray-50"
                  >
                    <input
                      type="checkbox"
                      className="mr-2"
                      checked={selectedRoles.includes(role)}
                      onChange={() => handleRoleChange(role)}
                    />
                    <span className="text-gray-700">{role}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="lg:w-3/4 w-full">
          <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
            {currentCards.map((card, index) => (
              <CardTeolojianina
                key={index}
                name={card.name}
                phone={card.phone}
                role={card.role}
                email={card.email}
                onClick={() => handleCardClick(card)}
              />
            ))}
          </div>
          {filteredCards.length > 0 && renderPagination()}
        </div>
      </div>

      <ModalTeolojianaDetails
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        details={selectedDetails}
      />
    </div>
  );
};

export default CardGrid;