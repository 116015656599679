import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import logo1 from "../../assets/images/Logos/1.png";
import logo2 from "../../assets/images/Logos/2.png";
import logo3 from "../../assets/images/Logos/3.png";
import logo4 from "../../assets/images/Logos/4.png";
import logo5 from "../../assets/images/Logos/5.png";
import logo6 from "../../assets/images/Logos/6.png";
import logo7 from "../../assets/images/Logos/7.png";

const images = [logo1, logo2, logo3, logo4, logo5, logo6, logo7];

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
};

function Carrousel() {
  return (
    <div
      className="relative container max-w-7xl mx-auto px-2 py-2 lg:px-[5%]"
      //   data-aos="flip-left"
      //   data-aos-easing="ease-out-cubic"
      //   data-aos-duration="2000"
    >
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="flex justify-center items-center px-2">
            <img
              src={image}
              alt={`Slide ${index}`}
              className="max-w-full h-28 object-contain"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Carrousel;
