import React from 'react';

function Word() {
    return (
        <div className="flex items-center justify-center text-center mt-16 bg-blue-100 py-14 px-4" data-aos="fade-up-left" data-aos-duration="1000">
            <div>
                <h1 className="text-3xl sm:text-4xl font-bold mb-4 text-blue-600">TENIN'ANDRIAMANITRA ATAO TSIANJERY</h1>
                <p className="text-lg sm:text-xl italic">...Ary hampitombo ny vokatry ny fahamarinanareo</p>
                <p className="text-lg sm:text-xl text-red-500 mt-2">II Korintiana 9:10</p>
                <p className="text-base sm:text-lg mt-2">Teny faneva ny FLM 2020</p>
            </div>
        </div>
    );
}

export default Word;
