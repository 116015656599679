import React from "react";
import TitleFLM from "../Components/FLM/Title";
import Carrousel from "../Components/FLM/Carrousel";
import President from "../Components/FLM/President";
import Sampandraharaha from "../Components/FLM/Sampandraharaha";

function About() {
  return (
    <div>
      <TitleFLM
        texttitle="Ny MARINA amin'ny FINOANA no ho VELONA. Romana 1:17 "
        heightTitle="400px"
      />
      <Carrousel />
      <President />
      <Sampandraharaha />
    </div>
  );
}

export default About;
