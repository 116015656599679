import React from "react";
import TitleFLM from "../Components/FLM/Title";
import CardFampianarana from "../Components/CardFampianarana";

function Study() {
  return (
    <div>
      <TitleFLM texttitle="Fampianarana" heightTitle="400px" />
      <CardFampianarana />
    </div>
  );
}

export default Study;
