import bdd from '../assets/json/bdd.json';
import perikopa from '../assets/json/bddPerikopa.json';
import fampianarana from '../assets/json/fampianarana.json';
import fiangonana from '../assets/json/fiangonana.json';

export const getSermonForToday = async () => {
    const today = new Date();
    const formattedDate = `${String(today.getMonth() + 1).padStart(2, '0')}${String(today.getDate()).padStart(2, '0')}`;
    return bdd.sermons.sermon.find(s => s._date === formattedDate);
};

export const getNextSundayPerikopa = async () => {
    const today = new Date();
    let nextSunday = new Date(today);

    // Calculer le prochain dimanche
    nextSunday.setDate(today.getDate() + (7 - today.getDay()));


    // Formater la date pour correspondre au format dans le fichier JSON (dd/mm/yyyy)
    const formattedDate = `${String(nextSunday.getDate()).padStart(2, '0')}/${String(nextSunday.getMonth() + 1).padStart(2, '0')}/${nextSunday.getFullYear()}`;

    // Retourner la péricope correspondant à cette date
    return perikopa.sermons.sermon.find(s => s.date === formattedDate);
};

export const getFiangonana = async () => {
    try {
        return fiangonana;
    } catch (error) {
        console.error('Erreur lors de la récupération des données fiangonana:', error);
        throw error;
    }
};

export const getFampianarana = async () => {
    try {
        return fampianarana;
    } catch (error) {
        console.error('Erreur lors de la création des données fampianarana:', error);
        throw error;
    }
};
